.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.pdf {
    width: 100%;
    height:80vh;
    border: none;
}

.resume {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}