.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.051);
    padding-bottom: 10px;
}

.right-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10px;
    gap: 10px;
    padding-top: 5px;
}

.right-header > a > img{
    width: 30px;
    height: 30px;
}

.center-header{
    padding-left: 43px;
}

@media screen and (max-width: 600px){
    .center-header{
        display: none;
    }
    
}