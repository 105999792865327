.card {
    width: 700px;
    margin-top: 30px;
    height: 350px
}

.media {
    width: 250px;
    height: 300px;
    object-fit: cover;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: left;
    height: 300px;
    overflow-y: scroll;
}

.text {
    width: 75%
}

.title {
    display: flex;
    align-items: left;
}

@media screen and (max-width: 1200px){
    .card {
        width: 700px;
    }    
}

@media screen and (max-width: 745px){
    .card {
        width: 450px;
    }
    
    .content {
        overflow: scroll;
        height: 300px;
    }
}

@media screen and (max-width: 500px){
    .card {
        width: 80%;
        height: 100%;
    }
    
    .card .media {
        display: none;
    }
    
    .content {
        overflow: scroll;
    }

    .contentContainer {
        align-items: center;
        text-align: center;
        height: 100%;
    }

    .text{
        width: 100%;
    }
}